.faq-title {
  text-align: center;
  margin-bottom: 2rem;
}

.faq-section {
  margin: 2rem 0;
}

.faq-list {
  display: flex;
  flex-direction: column;
}

.faq-item {
  margin-bottom: 2rem;
  cursor: pointer;
}

.faq-item h3 {
  font-size: 1.2rem;
  margin: 0;
  padding: 0.5rem 1rem;
  background-color: #eee;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq-item h3 .arrow {
  font-size: 1rem;
  margin-left: 0.5rem;
  transition: transform 0.2s ease-in-out;
}

.faq-item.open h3 .arrow {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 0rem 1rem;
  border-radius: 0.25rem;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-in-out;
}

.faq-answer.visible {
  max-height: 10rem;
}
