:root{
    --violet-primary: #647dee;
    --gradient: linear-gradient(90deg, #647dee, #7f53ac);
}

.c{
    height: 100vh;
    position: relative;
    margin-top: 5rem;
}

.c-bg{
    width: 8px;
    height: 100%;
    background: var(--primary);
    position: absolute;
    left: 0;
}

.c-wrapper{
    /* padding: 50px; */
    display: flex;
}

.c-left{
    flex: 1;
}

.c-right{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.c-title{
    font-size: 2.8rem;
    width: 90%;
}

.c-info-item{
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-size: 1.2rem;
    font-weight: 400;
}

.c-icon{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.c-desc{
    font-size: 1.3rem;
    font-weight: 300;
}

form{
    margin-top: 20px;
}

input{
    width: 90%;
    height: 60px;
    border: none;
    border-bottom: 1px solid #000;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    background: none;
}

textarea{
    width: 90%;
    margin: 40px 0px;
    font-size: 14px;
    padding-left: 10px;
    background: none;
    border-bottom: 1px solid #000;
}

button{
   width: 20%;
   border: none;
   padding: 15px;
   background: var(--primary-dark);
   color: white;
   font-weight: 500;
   cursor: pointer;  
   text-transform: uppercase;
   border-radius: 5px;
   box-shadow: var(--box-shadow-darker);
}

button:hover{
    opacity: .8;
}

.c-thanks{
    margin-top: 10px;
    font-weight: bold;
    font-size: 1.8rem;
}

@media (max-width: 959px){
    .c-wrapper{
        display: block;
    }

    .c-right__btn{
        width: 30%;
    }
}

@media screen and (max-width: 480px){
    .c-wrapper{
        flex-direction: column;
        padding: 0px 50px;
    }

    .c-title{
        font-size: 30px;
    }

    .c-info-item{
        margin: 20px 0px;
        width: 100%; 
    }

    .c-desc{
        display: block;
        margin-top: 3rem;
    }

    .c-input{
        width: 100%;
    }

    button{
        margin-top: 8px;
    }
}