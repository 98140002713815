.about_content p{
    color: black;
    width: 70%;
    margin: 5rem auto;
    text-align: center;
}

.owner{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 4rem;
}

.owner img{
    width: 500px;
    height: 400px;
}

.owner .title h2{
    margin-top: 4rem;
    font-size: 2.1rem;
    margin-bottom: 1rem;
}

.owner .title p{
    font-size: 14px;
    color: rgba(0, 0, 0, .8);
    line-height: 1.5rem;
    text-align: left;
}

.social_icons img{
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-top: 1.5rem;
}