body{
    background: #f4f4f4;
}

.vid_container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 15px;
    align-items: flex-start;
    padding: 5px 5%;
}

.vid_container .main-video{
    background: #fff;
    border-radius: 5px;
    padding: 10px;
}

.vid_container .main-video video{
    width: 100%;
    height: 440px;
}

.vid_container .main-video .title{
    color: #333;
    font-size: 23px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.vid_container .video-list{
    border-radius: 5px;
    height: 520px;
    overflow-y: scroll;
    background: #fff;
}

.vid_container .video-list::-webkit-scrollbar{
    width: 7px;
}

.vid_container .video-list::-webkit-scrollbar-track{
    background: #ccc;
    border-radius: 50px;
}

.vid_container .video-list::-webkit-scrollbar-thumb{
    background: #666;
    border-radius: 50px;
}

.vid_container .video-list .vid video{
    width: 250px;
    height: 100px;
    border-radius: 5px;
}

.vid_container .video-list .vid{
    display: flex;
    align-items: center;
    gap: 15px;
    background: #f7f7f7;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    border: 1px solid rgba(0,0,0,.1);
    cursor: pointer;
}

.vid_container .video-list .vid:hover{
    background: #eee;
}

.vid_container .video-list .vid.active{
    background: #2980b9;
}

.vid_container .video-list .vid.active .title{
    color: #fff;
}

.vid_container .video-list .vid .title{
    color: #333;
    font-size: 16px;
}

@media (max-width: 991px){
    .vid_container{
        grid-template-columns: 1.5fr 1fr;
        padding: 10px
    }
}

@media (max-width: 768px){
    .vid_container{
        grid-template-columns: 1fr;
    }
}