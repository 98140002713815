.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  transition: 0.3s;
  width: 100%;
}

.gallery:hover .gallery__image  {
  filter: grayscale(1);
}

.gallery__column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

/* .gallery__image:hover{
  filter: grayscale(0);
  transform: scale(1.1);
} */

.gallery__image:hover{
  transform: scale(1.1);
  filter: grayscale(0) !important;
}

/* .gallery__thumb{
  position: relative;
} */

.gallery__image{
  display: block;
  width: 100%;
  transition: .3s;
}
