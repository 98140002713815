@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  scroll-behavior: smooth;
  text-transform: none;
}

:root {
  --primary: #673de6;
  --primary-dark: #673de6;
  --dark: #0b0b45;
  --secondary: #fd5602;
  --light-color: #fa816c;
  --gradient: linear-gradient(-225deg, #ac32e4 0%, #7918f2 48%, #4801ff 100%);
  --box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --box-shadow-darker: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  --transition: 0.3s ease-in-out;
  --transition-prop: color, width, background-color;
}

.section {
  padding: 1rem 8%;
}

/* @media (max-width: 959px) {
  .section {
    padding: 1rem 10%;
  }
}

@media (max-width: 768px) {
  .section {
    padding: 1rem 10%;
  }
} */
