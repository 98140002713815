.icons {
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding: 2rem 9%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.icon_head {
  width: 80%;
  margin: auto;
}

.icon-title {
  /* margin-bottom: 3rem;
  font-weight: 600;
  color: var(--dark);
  font-size: 1.6rem; */
}

.icon_head p {
  font-size: 2.8rem;
}

.icon_imgs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 2rem; */
}

.iconArea {
  font-size: 2.6rem;
  color: var(--primary);
  cursor: pointer;
  transition: (--transition);
  transition-property: (--transition-prop);
  margin-bottom: 15px;
}

.iconArea:hover {
  color: var(--primary-dark);
}

@media (max-width: 959px) {
  .icon_imgs {
    display: block;
  }

  .imgs{
    margin-bottom: 20px;
  }
}